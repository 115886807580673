import cx from "classnames";
import * as _ from "lodash";
import React from "react";
import * as styles from "./OurValuesSection.module.scss";

class OurValuesSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      windowWidth: 0
    };
  }

  componentDidMount() {
    if (window) {
      window.addEventListener("resize", this.updateDimensions);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("resize", this.updateDimensions);
    }
  }

  updateDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };

  onChange = index => {
    const { currentIndex } = this.state;

    // compute the index that should be shown next
    const n = this.props.values.length; // length of values
    const nextIndex = ((index % n) + n) % n;
    if (nextIndex === currentIndex) {
      return;
    }
    this.setState({ currentIndex: nextIndex });
  };

  render() {
    const { currentIndex } = this.state;
    const sectionWidth = this.section ? this.section.clientWidth : 0;

    return (
      <section
        ref={ref => (this.section = ref)}
        className={styles.ourValuesSection}
      >
        <h5>{this.props.title}</h5>
        <div className={styles.controls}>
          {/* <ArrowButton
          disabled={currentIndex === 0}
          direction={ButtonDirection.LEFT}
          onClick={() => this.onChange(currentIndex - 1)} /> */}
          <div className={styles.dots}>
            {_.map(this.props.values, (value, i) => {
              const dotClass =
                i === currentIndex ? cx(styles.dot, styles.active) : styles.dot;
              return (
                <span className={dotClass} onClick={() => this.onChange(i)} />
              );
            })}
          </div>
          {/* <ArrowButton
          disabled={currentIndex === this.props.values.length - 1}
          direction={ButtonDirection.RIGHT}
          onClick={() => this.onChange(currentIndex + 1)} /> */}
        </div>

        <div
          className={styles.carousel}
          style={{
            marginLeft: `${-sectionWidth * 2 * currentIndex}px`
          }}
        >
          {_.map(this.props.values, (value, i) => {
            return (
              <div className={styles.valueContent}>
                <h1>{value.name}</h1>
                <p>{value.description.description}</p>
              </div>
            );
          })}
        </div>
      </section>
    );
  }
}

export default OurValuesSection;
