import cx from "classnames";
import GoogleMapReact from "google-map-react";
import * as _ from "lodash";
import React from "react";
import * as styles from "./MapSection.module.scss";
import { cleanAndSimpleStyle } from "./mapstyle";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const locations = [
  {
    name: "Portsmouth",
    address: "36 Maplewood Avenue, Portsmouth, NH, 03801",
    lat: 43.076938738959925,
    long: -70.76176426601975
  },
  {
    name: "Toronto",
    address: "Suite 360-144 Front Street West, Toronto, Ontario, M5J 2L7",
    lat: 43.645397,
    long: -79.3861457
  },
  {
    name: "Tokyo",
    address:
      "7F Iwanamishoten Hitotsubashi bldg Annex, 2-4-4 Hitotsubashi, Chiyoda City, Tokyo 101-0003",
    lat: 35.6946764,
    long: 139.7554613
  },
  {
    name: "London",
    address: "20 Air Street, London, United Kingdom, W1B 5AN",
    lat: 51.5105972,
    long: -0.1386891
  }
];

const MapSection: React.FC<{}> = props => {

  const {t} = useTranslation();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     currentIndex: 0
  //   };
  // }

  const renderMarker = (map, maps) => {
    _.forEach(locations, (location, idx) => {
      new maps.Marker({
        map: map,
        position: new maps.LatLng(location.lat, location.long),
        title: ""
      });
    });
  };

  const selectedLocation = locations[currentIndex];
  return (
    <section className={styles.mapSection}>
      <div className={styles.map}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_KEY }}
          options={{
            fullscreenControl: false,
            gestureHandling: "none",
            styles: cleanAndSimpleStyle
          }}
          center={[selectedLocation.lat, selectedLocation.long]}
          defaultZoom={14}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
        ></GoogleMapReact>
      </div>
      <div className={styles.locationPicker}>
        {_.map(locations, (location, idx) => {
          const classes =
            idx === currentIndex
              ? cx(styles.locationItem, styles.selected)
              : styles.locationItem;
          return (
            <div
              onClick={() => setCurrentIndex(idx)}
              className={classes}
            >
              <h4>{t(location.name)}</h4>
              <p>{t(location.address)}</p>
            </div>
          );
        })}
      </div>
      <div className={styles.extraSpace} />

      <div className={styles.mapsList}>
        {_.map(locations, (location, idx) => {
          return (
            <>
              <div className={styles.mapMobile}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_KEY }}
                  options={{
                    styles: cleanAndSimpleStyle
                  }}
                  center={[selectedLocation.lat, selectedLocation.long]}
                  defaultZoom={14}
                  yesIWantToUseGoogleMapApiInternals={true}
                  onGoogleApiLoaded={({ map, maps }) => {
                    new maps.Marker({
                      map: map,
                      position: new maps.LatLng(location.lat, location.long),
                      title: ""
                    });
                  }}
                ></GoogleMapReact>
              </div>
              <h4>{location.name}</h4>
              <p>{location.address}</p>
            </>
          );
        })}
      </div>
    </section>
  );
}

export default MapSection;
