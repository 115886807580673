import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import cx from "classnames";
import * as aboutUsStyles from "../AboutUs.module.scss";
import * as styles from "./OurInvestorsSection.module.scss";

class OurInvestorsSection extends React.Component {
  render() {
    const images = this.props.data;
    return (
      <section
        className={cx(styles.ourInvestorsSection, aboutUsStyles.container)}
      >
        <h2>{this.props.title}</h2>
        <div className={styles.investors}>
          <span onClick={() => window.open("https://svangel.com/", "_blank")}>
            <Img
              className={styles.investor}
              fixed={images.SVAngel.childImageSharp.fixed}
              alt="SV Angel"
            />
          </span>
          <span
            onClick={() => window.open("https://www.s28capital.com/", "_blank")}
          >
            <Img
              className={styles.investor}
              fixed={images.S28Capital.childImageSharp.fixed}
              alt="S28 Capital"
            />
          </span>
          <span onClick={() => window.open("https://lsvp.com/", "_blank")}>
            <Img
              className={styles.investor}
              fixed={images.lightspeed.childImageSharp.fixed}
              alt="Lightspeed"
            />
          </span>
          <span
            onClick={() =>
              window.open("http://www.conversioncapital.com/", "_blank")
            }
          >
            <Img
              className={styles.investor}
              fixed={images.conversionCapital.childImageSharp.fixed}
              alt="Conversion Capital"
            />
          </span>
          <span
            onClick={() => window.open("https://foundersfund.com/", "_blank")}
          >
            <Img
              className={styles.investor}
              fixed={images.foundersFund.childImageSharp.fixed}
              alt="Founders Fund"
            />
          </span>
          <span onClick={() => window.open("https://www.nyca.com", "_blank")}>
            <Img
              className={styles.investor}
              fixed={images.nyca.childImageSharp.fixed}
              alt="NYCA"
            />
          </span>
          <span onClick={() => window.open("https://cherubic.com/", "_blank")}>
            <Img
              className={styles.investor}
              fixed={images.cherubicVentures.childImageSharp.fixed}
              alt="Cherubic Ventures"
            />
          </span>
          <span onClick={() => window.open("https://company.co/", "_blank")}>
            <Img
              className={styles.investor}
              fixed={images.companyVentures.childImageSharp.fixed}
              alt="Company Ventures"
            />
          </span>
          <span onClick={() => window.open("http://eight.vc/", "_blank")}>
            <Img
              className={styles.investor}
              fixed={images.VC8.childImageSharp.fixed}
              alt="8VC"
            />
          </span>
          <span
            onClick={() =>
              window.open(
                "https://en.wikipedia.org/wiki/Allen_%26_Company",
                "_blank"
              )
            }
          >
            <Img
              className={styles.investor}
              fixed={images.allen.childImageSharp.fixed}
              alt="Allen & Co"
            />
          </span>
        </div>
      </section>
    );
  }
}

export default ({ title }) => (
  <StaticQuery
    query={graphql`
      query {
        # SV Angel
        SVAngel: file(relativePath: { eq: "investors/svangel@3x.png" }) {
          childImageSharp {
            # Specify a fixed image and fragment.
            # The default width is 400 pixels
            fixed(height: 52) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        # S28 Capital
        S28Capital: file(relativePath: { eq: "investors/s-28@3x.png" }) {
          childImageSharp {
            # Specify a fixed image and fragment.
            # The default width is 400 pixels
            fixed(height: 27) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        # Lightspeed
        lightspeed: file(relativePath: { eq: "investors/lightspeed@3x.png" }) {
          childImageSharp {
            # Specify a fixed image and fragment.
            # The default width is 400 pixels
            fixed(height: 29) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        # Conversion Capital
        conversionCapital: file(
          relativePath: { eq: "investors/conversion-captial@3x.png" }
        ) {
          childImageSharp {
            # Specify a fixed image and fragment.
            # The default width is 400 pixels
            fixed(height: 29) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        # Founders Fund
        foundersFund: file(
          relativePath: { eq: "investors/foundersfund@3x.png" }
        ) {
          childImageSharp {
            # Specify a fixed image and fragment.
            # The default width is 400 pixels
            fixed(height: 22) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        # NYCA
        nyca: file(relativePath: { eq: "investors/nyca@3x.png" }) {
          childImageSharp {
            # Specify a fixed image and fragment.
            # The default width is 400 pixels
            fixed(height: 38) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        # Cherubic Ventures
        cherubicVentures: file(
          relativePath: { eq: "investors/cherubic-ventures@3x.png" }
        ) {
          childImageSharp {
            # Specify a fixed image and fragment.
            # The default width is 400 pixels
            fixed(height: 40) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        # Company Ventures
        companyVentures: file(
          relativePath: { eq: "investors/companyventures@3x.png" }
        ) {
          childImageSharp {
            # Specify a fixed image and fragment.
            # The default width is 400 pixels
            fixed(height: 56) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        VC8: file(relativePath: { eq: "investors/8-vc@3x.png" }) {
          childImageSharp {
            # Specify a fixed image and fragment.
            # The default width is 400 pixels
            fixed(height: 42) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        allen: file(relativePath: { eq: "investors/allen-company@3x.png" }) {
          childImageSharp {
            # Specify a fixed image and fragment.
            # The default width is 400 pixels
            fixed(height: 33) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => <OurInvestorsSection data={data} title={title} />}
  />
);
