import { graphql } from "gatsby";
import React from "react";
import AboutUsBanner from "../components/AboutUsPage/AboutUsBanner";
import FounderLetterSection from "../components/AboutUsPage/FounderLetterSection";
import MapSection from "../components/AboutUsPage/MapSection";
import OurInvestorsSection from "../components/AboutUsPage/OurInvestorsSection";
import OurMissionSection from "../components/AboutUsPage/OurMissionSection";
import OurValuesSection from "../components/AboutUsPage/OurValuesSection";
import BottomCTA from "../components/BottomCta";
import { NavigationBarVariant } from "../components/NavigationBar/models";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import teamphoto from "../images/teamphoto.png";
import { nav } from "../utils/navigation";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const AboutPage: React.FC<{}> = props => {
  const aboutpages = props?.data?.aboutContent?.edges;
  const currentAboutPage = aboutpages && aboutpages[0].node;

  const {t} = useTranslation();

  return (
    <Layout navigationBarVariant={NavigationBarVariant.light}>
      <SEO
        title={t("About us")}
        description={t("At Perpetua, we're obsessed with building advertising tools that unlock predictable, sustainable growth for anyone who sells online. We leverage AI to enhance human creativity - not replace it. Let's grow together")}
      />
      <AboutUsBanner
        title={currentAboutPage.bannerTitle}
        subtitle={currentAboutPage.bannerSubtitle}
        style={{
          backgroundImage: `linear-gradient(286deg, rgba(66, 165, 245, 0.8), rgba(130, 65, 243, 0.8)), url("${teamphoto}")`,
          height: `390px`
        }}
        src={teamphoto}
        gradientOverlay={true}
      />
      <FounderLetterSection
        title={currentAboutPage.founderLetterTitle}
        body={currentAboutPage.founderLetterBody.founderLetterBody}
        signature={currentAboutPage.founderLetterSignature.fluid}
      />
      <OurMissionSection
        title={currentAboutPage.missionSectionTitle}
        description={currentAboutPage.missionSectionDescription}
        boxes={currentAboutPage.missionSectionBoxes}
      />
      <OurValuesSection
        title={currentAboutPage.valuesSectionTitle}
        values={currentAboutPage.valuesSectionValues}
      />
      <OurInvestorsSection title={currentAboutPage.investorSectionTitle} />
      <MapSection />
      <BottomCTA
        title={t("Join Us")}
        subtitle={t("Work alongside our talented, fast growing team.")}
        primaryButtonText={t("View Openings")}
        secondaryButtonText={t("Request a Demo")}
        primaryButtonOnClick={() => {
          nav("https://jobs.lever.co/perpetualabs.com");
        }}
        secondaryButtonId={"aboutus-demo"}
      />
    </Layout>
  );
}

export default AboutPage;

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    aboutContent: allContentfulAboutPage(
      sort: { fields: [createdAt], order: DESC },
      filter: { node_locale: {eq: $language }}
      ) {
      edges {
        node {
          metadataTitle
          metadataDescription
          bannerTitle
          bannerSubtitle
          founderLetterTitle
          founderLetterBody {
            founderLetterBody
          }
          founderLetterSignature {
            fluid(maxWidth: 89) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          missionSectionTitle
          missionSectionDescription
          missionSectionBoxes {
            title
            metric
          }
          visionSectionTitle
          visionSectionDescription
          valuesSectionTitle
          valuesSectionValues {
            name
            description {
              description
            }
          }
          teamSectionTitle
          teamSectionHeadshots {
            name
            jobTitle
            linkedIn
            headShot {
              fluid(maxWidth: 260) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            petHeadShot {
              fluid(maxWidth: 260) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          investorSectionTitle
          bottomCtaTitle
          bottomCtaSubtitle
          bottomCtaButtonText
          createdAt
        }
      }
    }
  }
`;
