import { marked } from "marked";
import React from "react";
import * as styles from "./FounderLetterSection.module.scss";

const FounderLetterSection: React.FC<{}> = props => {
  const { body } = props;
  const formattedText = marked(body);
  
  return <section className={styles.founderLetterSection}>
      <div className={styles.letter}>
        <span dangerouslySetInnerHTML={{ __html: formattedText }}></span>
      </div>
    </section>
};

export default FounderLetterSection;